/**
 * Created by WebStorm.
 * User: athukorala
 * Date: 7/22/20
 * Time: 12:36 PM
 */
import React from 'react';
import './Loader.scss';

const App = (props) => {
    return (
        <div className={"loader-teach-css"}>
            <div className={"loader-wrapper"}>
                <div className={"loader-main"}>
                    <div className="loader"/>
                </div>
            </div>
        </div>
    )
};

export default App;
