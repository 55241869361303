/**
 * Created by Udara Prabath
 * Date: 10/20/2020
 * Using: IntelliJ IDEA
 */
import React from 'react';
import * as actionTypes from './actionType';

import avatar from '../../../assets/img/institute/avatar-placeholder.png'

const initialState = {
    lecturer: {
        name: "",
        about: '',
        image: avatar,
        defaultImage: '',
        qualification: ''
    },
};

const reducer = (state = initialState, action) => {
    switch (action.type) {

        case actionTypes.FETCH_LECTURE:
            return {
                ...state,
                lecturer: action.value
            };
        default:
            return state;
    }
};

export default reducer;