/**
 * Created by WebStorm.
 * User: athukorala
 * Date: 7/24/20
 * Time: 3:23 PM
 */
import React from 'react';
import * as actionTypes from './actionType';

import instituteLogo from '../../../assets/img/institute/nuwan.png';
import defaultImage from '../../../assets/img/institute/sakya-default.jpg';
import i from "react-icofont";


let about =
    <div>
        <p className={"none-margin_ text-align-justify"}>
             - 6 TO 11 Subjects
        </p>
        <p className={"none-margin_ text-align-justify"}>
            - And Advance Level Pure Mathematics
        </p>
    </div>;
let qualification =
    <div>
        <h6
            style={{fontSize: 12}} ><i className="icofont-location-pin"/>
            &nbsp;&nbsp;&nbsp; Behind the police station,
            Weerakatiya Road,
            Walasmulla.
        </h6>
        <h6
            style={{fontSize: 12}} ><i className="icofont-phone"/>
            &nbsp;&nbsp;&nbsp; 077-2943454 / 070-1381844 / 071-3624348
        </h6>
    </div>;

const initialState = {
    institute: {
        name: "New Leeds Higher Education Center",
        about: about,
        image: instituteLogo,
        defaultImage: defaultImage,
        qualification: qualification
    },
    teachers: []
};

const reducer = (state = initialState, action) => {
    switch (action.type) {

        case actionTypes.INSTITUTE_HANDLER:
            return {
                ...state,
                institute: action.value
            };
        case actionTypes.FETCH_TEACHERS:
            return {
                ...state,
                teachers: action.value
            };
        default:
            return state;
    }
};

export default reducer;
